<template>
  <el-dialog :lock-scroll="true" v-model="show">

    <template v-slot:title>
      <div  style="border-bottom: #e0e0e0 1px solid;padding: 20px;">
        <div class="modal-title" >{{title}}</div>
      </div>
    </template>
    <template v-slot:footer>
  <div style="border-top: #e0e0e0 1px solid; padding: 20px; display: flex; justify-content: space-between;">
    <el-button type="danger" plain @click="doCancel()">{{ KT('cancel') }}</el-button>


    <el-switch
      v-model="useServerCommandId"
      active-text="Usar CommandId del servidor"
      inactive-text="Usar CommandId del dispositivo"
      :active-value="true"
      :inactive-value="false"
    ></el-switch>
    
    <!-- Contadores fuera del botón -->
   


    

    <!-- Botón "Guardar" deshabilitado si hay dispositivos sin commandId -->
     <!-- Botón Guardar deshabilitado si hay dispositivos sin CommandId -->
  <el-button 
    type="primary" 
    @click="doSave()" 
    :disabled="devicesWithoutCommandId > 0">
    Guardar
  </el-button>
  </div>
</template>


    <el-tabs v-model="tab">
     
      <el-tab-pane :label="KT('notification.notification2')" name="first">
        
        <el-form label-width="120px" label-position="top">
          <el-form-item :label="KT('notification.type')" >
            <el-select v-model="formData.type" filterable :size="'large'" :placeholder="KT('notification.type')" :no-match-text="KT('NO_MATCH_TEXT')" :no-data-text="KT('NO_DATA_TEXT')">
              <el-option
                  v-for="item in optionType"
                  :key="item.type"
                  :label="KT('notification.types.'+item.type)"
                  :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>

<!--
          <el-form-item v-if="formData.type==='alarm'" :label="KT('notification.alarms')" >
            <el-select v-model="formData.attributes['alarms']" filterable multiple :size="'large'"  :placeholder="KT('notification.alarms')" :no-match-text="KT('NO_MATCH_TEXT')" :no-data-text="KT('NO_DATA_TEXT')">
              <el-option
                  v-for="item in alarmType"
                  :key="item"
                  :label="KT('alarms.'+item)"
                  :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>

          
       

          <el-form-item :label="KT('notification.autoAdd')" >
            <el-switch
                v-model="formData.attributes['tarkan.autoadd']"
                :inactive-text="KT('no')"
                :active-text="KT('yes')"
            >
            </el-switch>
          </el-form-item>

 -->
            <el-form-item :label="KT('notification.all')">
              <el-switch
                v-model="formData.always"
                :inactive-text="KT('no')"
                :active-text="KT('yes')"
                :disabled="true" 
              >
              </el-switch>
            </el-form-item>



          <el-form-item :label="KT('notification.channel')" >
            <el-select v-model="formData.notificators" filterable  :size="'large'"  :placeholder="KT('notification.channel')" :no-match-text="KT('NO_MATCH_TEXT')" :no-data-text="KT('NO_DATA_TEXT')">
              <el-option
                  v-for="item in options"
                  :key="item.type"
                  :label="KT('notification.channels.'+item.type)"
                  :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>

        </el-form>
      


  <el-form-item :label="KT('notification.calendar')">
  

<!-- Select para mostrar los calendarios habilitados -->
<el-select v-model="formData.calendarId" filterable :size="'large'" :placeholder="KT('notification.selectCalenders')" :no-match-text="KT('NO_MATCH_TEXT')" :no-data-text="KT('NO_DATA_TEXT')">
  <el-option
    v-for="calendar in filteredCalendars"
    :key="calendar.id"
    :label="calendar.name"
    :value="calendar.id"
  ></el-option>
</el-select>
  </el-form-item>


      </el-tab-pane>
<!--

      
      <el-tab-pane :label="KT('notification.customize')" name="customize">
        <el-form label-width="120px" label-position="top">

          <el-form-item :label="KT('notification.pin')" >
            <el-switch
                v-model="customizeData.pin"
                :inactive-text="KT('no')"
                :active-text="KT('yes')"
            >
            </el-switch>
          </el-form-item>

          <el-form-item :label="KT('notification.position')" >
            <el-select v-model="customizeData.position" filterable :size="'large'" :placeholder="KT('notification.position')" :no-match-text="KT('NO_MATCH_TEXT')" :no-data-text="KT('NO_DATA_TEXT')">
              <el-option
                  v-for="item in customizePosition"
                  :key="item.value"
                  :label="KT('notification.positions.'+item.value)"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>


          <el-form-item :label="KT('notification.color')" >
            <el-select v-model="customizeData.color" filterable :size="'large'" :placeholder="KT('notification.color')" :no-match-text="KT('NO_MATCH_TEXT')" :no-data-text="KT('NO_DATA_TEXT')">
              <el-option
                  v-for="item in customizeColors"
                  :key="item.value"
                  :label="KT('notification.colors.'+item.value)"
                  :value="item.value"
              >

                <span style="float: left;margin-top: 7px;display: block;width: 20px;height: 20px;" :style="{'background': item.color}"></span>
                <span style="margin-left: 10px;">{{ KT('notification.colors.'+item.value) }}</span>
              </el-option>
            </el-select>
          </el-form-item>



          <el-form-item :label="KT('notification.sound')" >
            <el-select v-model="customizeData.sound" filterable :size="'large'" :placeholder="KT('notification.sound')" :no-match-text="KT('NO_MATCH_TEXT')" :no-data-text="KT('NO_DATA_TEXT')">
              <el-option
                  v-for="item in customizeSounds"
                  :key="item.value"
                  :label="KT('notification.sounds.'+item.value)"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>


          <el-form-item v-if="customizeData.sound==='custom'" :label="KT('notification.soundURL')" >
            <el-input v-model="customizeData.soundURL"></el-input>
          </el-form-item>



        </el-form>


      </el-tab-pane>
    -->
      
      
  <!-- Pestaña de atributos -->
  <el-tab-pane :label="KT('attribute.attributes')" name="fourth">
    <tab-attributes v-model="formData.attributes" :type="'notification'"></tab-attributes>
  </el-tab-pane>

  <!-- Pestaña de dispositivos (activo por defecto) -->
  <el-tab-pane :label="KT('device.devices')" name="devices" :disabled="formData.always">
    <tab-devices ref="deviceList"></tab-devices>
  </el-tab-pane>

  <!-- Pestaña de grupos (activo por defecto) -->
  <el-tab-pane :label="KT('group.groups')" name="groups" :disabled="formData.always">
    <tab-groups ref="groupList"></tab-groups>
  </el-tab-pane>

  <!-- Pestaña de usuarios (deshabilitada) -->
  <el-tab-pane :label="KT('user.users')" name="users" :disabled="true">
    <tab-users ref="userList"></tab-users>
  </el-tab-pane>
</el-tabs>
  </el-dialog>
</template>


<script setup>


import 'element-plus/es/components/input/style/css'
import 'element-plus/es/components/button/style/css'
import 'element-plus/es/components/switch/style/css'
import 'element-plus/es/components/select/style/css'
import 'element-plus/es/components/option/style/css'
import 'element-plus/es/components/dialog/style/css'
import 'element-plus/es/components/tab-pane/style/css'
import 'element-plus/es/components/tabs/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/checkbox/style/css'

import {ElDialog,ElMessage,ElNotification,ElTabs,ElTabPane,ElForm,ElFormItem,ElSelect,ElOption,ElButton} from "element-plus";
//import {ElInput,ElSwitch} from "element-plus";
import {ElSwitch} from "element-plus";
import {ref,defineExpose,nextTick,computed} from 'vue';
import {useStore} from 'vuex'

const store = useStore();
const isEdit = ref(false);
const query = ref('');
import KT from '../../../tarkan/func/kt'



import TabAttributes from "./tab-attributes";
import TabGroups from './tab-groups';
import TabDevices from './tab-devices'
import TabUsers from './tab-users'

const deviceList = ref(null);
const userList = ref(null);
const groupList = ref(null);



const options = ref([]);
const optionType = ref([]);
//const alarmType = ref("general,sos,vibration,movement,lowspeed,overspeed,fallDown,lowPower,lowBattery,fault,powerOff,powerOn,door,lock,unlock,geofence,geofenceEnter,geofenceExit,gpsAntennaCut,accident,tow,idle,highRpm,hardAcceleration,hardBraking,hardCornering,laneChange,fatigueDriving,powerCut,powerRestored,jamming,temperature,parking,shock,bonnet,footBrake,fuelLeak,tampering,removing".split(","));
//const testNotification = ()=>{
 // window.$traccar.testNotification();

  //ElMessage.success(KT('notification.testSent'))

//}

//const customizeColors = ref([
//  {value: 'soft-red',label: 'Vermelho Claro',color: '#ffdddd'},
 // {value: 'red',label: 'Vermelho',color: '#f44336'},
//  {value: 'soft-green',label: 'Verde Claro',color: '#ddffdd'},
//  {value: 'green',label: 'Verde',color: '#4CAF50'},
 // {value: 'soft-yellow',label: 'Amarelo Claro',color: '#ffffcc'},
 // {value: 'yellow',label: 'Amarelo',color: '#ffeb3b'},
 // {value: 'soft-info',label: 'Azul Claro',color: '#ddffff'},
 // {value: 'info',label: 'Azul',color: '#2196F3'},
//]);

//const customizePosition = ref([
//  {value: 'top-right',name: 'Superior Direito'},
//  {value: 'top-left',name: 'Superior Esquerdo'},
//  {value: 'bottom-right',name: 'Inferior Direito'},
//  {value: 'bottom-left',name: 'Inferior Esquerdo'}
//]);

//const customizeSounds = ref([
//  {value: 'mute'},
//  {value: 'custom'},
//  {value: 'audio1'},
//  {value: 'audio2'},
//  {value: 'audio3'},
///  {value: 'audio4'},
 // {value: 'audio5'},
//  {value: 'audio6'},
//  {value: 'audio7'},
//  {value: 'audio8'},
 // {value: 'audio9'},
//  {value: 'audio10'},
//  {value: 'audio11'},
//  {value: 'audio12'},
 // {value: 'audio13'},
 // {value: 'audio14'},
 // {value: 'audio15'},
 // {value: 'audio16'},
 // {value: 'audio17'},
 // {value: 'audio18'},
 // {value: 'audio19'},
//  {value: 'audio20'},
//  {value: 'audio21'},
//  {value: 'audio22'},
//  {value: 'audio23'},
 // {value: 'audio24'}
//]);

const title = ref('');

const show = ref(false);
//const tab = ref('first');
const tab = ref('devices');

const customizeData = ref({
  position: '',
  color: '',
  sound: '',
  soundURL: '',
  pin: false
});

//const doPlay = ()=>{
 // if(customizeData.value.sound!=='') {
 //   store.dispatch("events/playSound",customizeData.value.sound);
 // }
//}

const onShow = ()=>{
  window.$traccar.getNotificators().then(({data})=>{
    //options.value = data;
    options.value = data.filter(item => item.type === 'command');
  });

  window.$traccar.getNotificationTypes().then(({data})=>{
    //optionType.value = data;
    optionType.value = data.filter(item => item.type === 'ignitionOn' //|| item.type === 'ignitionOff'
     );
  });
  
}

const defaultNotificationData = {
  id: 0,
  attributes: {},
  calendarId: null,
  always: false,
  type: "",
  notificators: ""}


// eslint-disable-next-line no-undef
const formData = ref(defaultNotificationData);




const newEvent = ()=>{
  tab.value = 'first';
  onShow(true);

  isEdit.value = false;
  title.value = KT('notification.newEvent');


  show.value = true;
	nextTick(()=>{
  groupList.value.clear();
  deviceList.value.clear();
  userList.value.clear();
 });
  // eslint-disable-next-line no-undef
    formData.value = JSON.parse(JSON.stringify(defaultNotificationData));
    customizeData.value = {
      position: '',
      color: '',
      sound: '',
      soundURL: '',
      pin: false
    };
}

const EditEvent = (id)=>{

  if(id===0){
    ElMessage.error(KT('notification.selectError'));
    return false;
  }

  onShow();
  isEdit.value = true;

  title.value = KT('notification.editEvent');
  tab.value = 'first';
  // eslint-disable-next-line no-undef
  formData.value = JSON.parse(JSON.stringify(defaultNotificationData));
  const notification = JSON.parse(JSON.stringify(store.getters["events/getNotificationById"](id)));

  formData.value.id = notification.id;
  formData.value.type = notification.type;
  formData.value.always = notification.always;
  formData.value.attributes = notification.attributes;
  
  formData.value.notificators = notification.notificators.split(",");

  customizeData.value = {
    position: '',
    color: '',
    sound: '',
    soundURL: '',
    pin: false
  };

  if(formData.value.attributes['tarkan.position']){
    customizeData.value.position = formData.value.attributes['tarkan.position'];
  }

  if(formData.value.attributes['tarkan.color']){
    customizeData.value.color = formData.value.attributes['tarkan.color']
  }

  if(formData.value.attributes['tarkan.sound']){
     customizeData.value.sound = formData.value.attributes['tarkan.sound']
  }

  if( formData.value.attributes['tarkan.soundURL'] ){
    customizeData.value.soundURL =  formData.value.attributes['tarkan.soundURL'] ;
  }

  if(formData.value.attributes['tarkan.pin']){
      customizeData.value.pin = formData.value.attributes['tarkan.pin'];
  }


  show.value = true;
}

defineExpose({
  newEvent,
 EditEvent,
});


const doCancel = ()=>{
  show.value = false;
}

// Actualización de la función de guardado:
// Función para guardar la notificación y realizar la vinculación
const useServerCommandId = ref(true); // El valor por defecto del switch es `true`


// Esta función realiza la lógica de guardado
const doSave = async () => {
  console.log("doSave ha sido llamado");

  // Mostrar notificación de información mientras se guarda
  ElNotification({
    title: 'Información',
    message: 'Guardando...',
    type: 'info',
  });

  // Validar campos antes de proceder con el guardado
  if (validateFormData()) {
    const selectedDevices = deviceList.value.selected;

    if (selectedDevices && selectedDevices.length > 0) {
      // Usar el getter de Vuex para obtener el commandId desde los atributos del servidor
      const commandIdFromServer = store.getters['server/getAttribute']('commandId', null);
      console.log("commandId desde Vuex (servidor):", commandIdFromServer);

      // Para cada dispositivo seleccionado, se crea una notificación individual
      for (let device of selectedDevices) {
        const notificationData = { ...formData.value };

        // Asignamos el ID y nombre del dispositivo a los atributos de la notificación
        notificationData.attributes['deviceId'] = device.id;
        notificationData.attributes['deviceName'] = device.name;
        notificationData.attributes['Placa'] = device.attributes.placa;

        // Verificar si existe el 'commandId' en el dispositivo, si no, buscarlo en 'commandIdFromServer'
        let commandId = device.attributes.commandId;
        console.log("commandId desde dispositivo:", commandId);

        // Si no existe commandId en el dispositivo, buscamos en serverAttributes
        if (!commandId && commandIdFromServer) {
          commandId = commandIdFromServer;  // Obtenemos el commandId desde Vuex
          console.log("commandId obtenido desde Vuex (servidor):", commandId);
        }

        // Asignar 'commandId' dentro de 'attributes' y también en formData
        notificationData.attributes['commandId'] = commandId;
        notificationData.commandId = commandId;

        // Verificar que el 'commandId' esté correctamente asignado
        console.log("commandId asignado:", notificationData.commandId);

        // Proceder con el guardado de la notificación para cada dispositivo
        try {
          const savedData = await store.dispatch('events/save', notificationData);

          if (!savedData || !savedData.id) {
            ElNotification({
              title: 'Error',
              message: 'Hubo un error al guardar.',
              type: 'error',
            });
            return;
          }

          const notificationId = savedData.id;
          console.log("Notificación guardada con ID:", notificationId);

          await linkObjectsToDeviceGroupUserNotification(notificationId, device.id);

          if (commandId) {
            await linkObjectsToDeviceGroupUserCommand(commandId, device.id);
          }

          ElNotification({
            title: 'Éxito',
            message: 'Guardado correctamente.',
            type: 'success',
          });

        } catch (error) {
          console.error("Error al guardar la notificación:", error);
          ElMessage.error('Hubo un error al guardar.');
        }
      }

      show.value = false;
    } else {
      ElNotification({
        title: 'Error',
        message: 'No hay dispositivos asignados.',
        type: 'error',
      });
    }
  } else {
    ElNotification({
      title: 'Error',
      message: 'Por favor completa los campos requeridos.',
      type: 'error',
    });
  }
};


// Función para vincular la notificación a dispositivos, grupos o usuarios
const linkObjectsToDeviceGroupUserNotification = async (notificationId, deviceId) => {
  const linkPromises = [];

  // Vincular solo el dispositivo actual
  if (deviceId) {
    linkPromises.push(linkObject({ deviceId, notificationId }));
  }

  // Vincular a grupos seleccionados
  if (groupList.value.selected.length > 0) {
    for (let group of groupList.value.selected) {
      linkPromises.push(linkObject({ groupId: group.id, notificationId }));
    }
  }

  // Vincular a usuarios seleccionados
  if (userList.value.selected.length > 0) {
    for (let user of userList.value.selected) {
      linkPromises.push(linkObject({ userId: user.id, notificationId }));
    }
  }

  await Promise.all(linkPromises);
};

// Función para vincular objetos (dispositivo, grupo o usuario)
const linkObject = async ({ deviceId, groupId, userId, notificationId }) => {
  let linkData = {};

  if (deviceId) {
    linkData = { deviceId, notificationId };
  } else if (groupId) {
    linkData = { groupId, notificationId };
  } else if (userId) {
    linkData = { userId, notificationId };
  }

  ElNotification({
    title: 'Info',
    message: KT('notification.linkToDevice', linkData),
    type: 'info',
  });

  await window.$traccar.linkObjects(linkData);
};

// Función para vincular el evento con el comando
const linkObjectsToDeviceGroupUserCommand = async (commandId, deviceId) => {
  const linkPromises = [];

  // Vincular solo el dispositivo actual con el commandId
  if (deviceId) {
    linkPromises.push(linkObjectCommand({ deviceId, commandId }));
  }

  // Vincular a grupos seleccionados con commandId
  if (groupList.value.selected.length > 0) {
    for (let group of groupList.value.selected) {
      linkPromises.push(linkObjectCommand({ groupId: group.id, commandId }));
    }
  }

  // Vincular a usuarios seleccionados con commandId
  if (userList.value.selected.length > 0) {
    for (let user of userList.value.selected) {
      linkPromises.push(linkObjectCommand({ userId: user.id, commandId }));
    }
  }

  // Esperar a que todas las vinculaciones se completen
  try {
    await Promise.all(linkPromises);
    console.log("Vinculación completada con éxito para todos.");
  } catch (error) {
    console.error("Error al vincular comandos:", error);
    ElNotification({
      title: 'Error',
      message: 'Error en la vinculación de los objetos con commandId.',
      type: 'error',
    });
  }
};

// Función base para vincular el comando
const linkObjectCommand = async ({ deviceId, groupId, userId, commandId }) => {
  let linkData = {};

  // Asegúrate de que commandId esté presente
  console.log("Vinculando con commandId:", commandId);

  // Asociar al dispositivo
  if (deviceId) {
    linkData = { deviceId, commandId };
  } 
  // Asociar al grupo
  else if (groupId) {
    linkData = { groupId, commandId };
  } 
  // Asociar al usuario
  else if (userId) {
    linkData = { userId, commandId };
  } else {
    console.error("Faltan parámetros para la vinculación");
    ElNotification({
      title: 'Error',
      message: 'Faltan parámetros necesarios para la vinculación',
      type: 'error',
    });
    return;
  }

  console.log("Datos para vincular:", linkData);

  try {
    await window.$traccar.linkObjects(linkData);
    console.log("Vinculación exitosa:", linkData);
  } catch (error) {
    console.error("Error al vincular:", error);
    ElNotification({
      title: 'Error',
      message: 'Error al realizar la vinculación.',
      type: 'error',
    });
  }
};



//const selectedDevices = ref([])






// Actualiza la función de validación para incluir la validación de 'alarms'
const validateFormData = () => {
  console.log("Validating formData:", formData.value);

  let isValid = true;
  let errorMessage = '';

  // Verifica que 'type' no esté vacío
  if (formData.value.type === '') {
    isValid = false;
    errorMessage += KT('validation.typeRequired') + ' ';
  }

  // Si el tipo es 'alarm', verifica que 'alarms' no esté vacío
  if (formData.value.type === 'alarm' && (!formData.value.attributes['alarms'] || formData.value.attributes['alarms'].length === 0)) {
    isValid = false;
    errorMessage += KT('validation.alarmsRequired') + ' ';
  }

  // Verifica que 'notificators' no esté vacío
  if (formData.value.notificators.length === 0) {
    isValid = false;
    errorMessage += KT('validation.notificatorsRequired') + ' ';
  }

  // 

  if (!isValid) {
    console.log("Validation failed:", errorMessage);
    return false;
  }

  console.log("Validation passed!");
  return true;
};





 
const filteredCalendars = computed(() => {
  // Obtenemos los calendarios habilitados desde el getter de Vuex
  

  // Si el query está vacío, devolvemos todos los calendarios habilitados
  if (query.value === '') {
    
    return store.state.calendars.calendarList;
  } else {
    // Filtramos los calendarios basándonos en el nombre del calendario
    return store.state.calendars.calendarList.filter((calendar) => {
      return calendar.name.toLowerCase().includes(query.value.toLowerCase());
    });
  }
});


// Computada para acceder al valor de devicesWithoutCommandId desde el hijo
const devicesWithoutCommandId = computed(() => {
  return deviceList.value?.devicesWithoutCommandId ?? 0;
});







</script>

<style>

.el-select.el-select--large{
  width: 100%;
}

.el-dialog__header,.el-dialog__body,.el-dialog__footer{
  padding: 0px !important;
}

.el-dialog__footer{
  margin-top: 20px;
}

.el-tabs__nav-wrap{
  padding-left: 20px;
  padding-right: 20px;
}

.el-tabs__content{
  padding-left: 20px;
  padding-right: 20px;
}




</style>